.block-accordion {

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    padding: 3rem 0;
    border: 0;

    .intro {
        text-align: center;
        margin-bottom: 2.5rem;

        .title {

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            margin: 0 0 1.5rem;

            h2 {
                @extend .typo-h2;
                margin: 0;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
            }
        }

        .subtitle {
            @extend .typo-label;
        }
    }

    .accordion-item {

        border: 0;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        .wrapper {
            border-radius: 1rem;
            overflow: clip;

            .trigger {
                background: $white;
                border-radius: 1rem;
                border: 1px solid $color;

                .title {

                    @include media-breakpoint-down(md) {
                        padding: 1rem;
                    }

                    padding: 1rem 1.5rem;
                    color: $dark;
                    display: flex;
                    align-items: center;
                    -webkit-appearance: none;

                    h4 {
                        @extend .typo-h5;
                        font-weight: 1.25rem;
                        font-weight: bold;
                        margin: 0;
                        text-transform: none;
                    }

                    i {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 2rem;
                        line-height: 1.25;
                        margin-left: auto;
                        transition: .15s ease-in-out;
                    }

                    &:hover {
                        // background: $dark;
                        color: $color;

                        i {
                            --fa-secondary-color: #FAA21B;
                        }
                    }

                    &:not(.collapsed) {
                        // background: $dark;
                        color: $color;

                        i {
                            --fa-secondary-color: #FAA21B;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .content {
                padding: 1.5rem;

                .text {
                    @extend .typo-rich;
                }
            }

            &.inverted {
                border: 0;
                background: $light 1rem;
                .trigger {

                    border: 0;
                    background: $color;

                    .title {

                        color: $white;
                    }
                }
            }
        }

    }

}