.block-video {
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .intro {
        text-align: center;
        margin-bottom: 2.5rem;

        .title {
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            h2 {
                @extend .typo-h2;
                margin: 0;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
            }
        }

        .subtitle {
            @extend .typo-label;
        }
    }

    .video {
        video {
            background: $light;
            position: relative;

            &[poster] {
                height: 100%;
                width: 100%;
            }
        }
    }
}