.product-header {
    background: $white;
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .content {
        text-align: left;
        z-index: 1;

        .title {
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            @extend .typo-h1;
            margin-top: 0;
        }

        .subtitle {
            @extend .typo-label;
            color: $color;
        }

        .description {
            @extend .typo-rich;
        }
    }

    .image {
        position: relative;

        @include media-breakpoint-down(md) {
            align-self: flex-end;
        }

        picture,
        img {
            width: 100%;
            object-fit: contain;
            object-position: top;
        }
    }

    .meta {
        margin-top: 1.5rem;
        padding: 0 1rem;

        .meta-box {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.125rem;
            padding: 1rem 1rem;
            text-align: center;
            text-transform: uppercase;

            &:first-child {
                border-radius: .5rem 0 0 .5rem;

                @include media-breakpoint-down(md) {
                    border-radius: 0;
                }
            }

            &:last-child {
                border-radius: 0 .5rem .5rem 0;

                @include media-breakpoint-down(md) {
                    border-radius: 0;
                }
            }
        }

        .size {
            background-color: $color;
            color: $white;
            // border-radius: .5rem 0 0 .5rem;
            position: relative;
            z-index: 2;

            @include media-breakpoint-down(md) {
                border-radius: .5rem;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: -.5rem;
                width: 1rem;
                background-color: $color;
                z-index: 1;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    height: 1rem;
                    bottom: -.5rem;
                    top: auto;
                    right: 0;
                    left: 0;
                }
            }
        }

        .weight {
            background-color: $dark;
            color: $white;
            // border-radius: .5rem 0 0 .5rem;
            position: relative;
            z-index: 2;

            @include media-breakpoint-down(md) {
                border-radius: .5rem;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: -.5rem;
                width: 1rem;
                background-color: $dark;
                z-index: 1;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    height: 1rem;
                    bottom: -.5rem;
                    top: auto;
                    right: 0;
                    left: 0;
                }
            }
        }

        .tech {
            background-color: $light;
            color: $dark;
            // flex-direction: column;
            gap: .5rem;
            // border-radius: .5rem;
            position: relative;
            z-index: 2;
            text-decoration: none;

            &:hover {
                background: $color;
                color: $white;
            }
        }

        .contact {
            background-color: $color;
            color: $white;
            // flex-direction: column;
            gap: .5rem;
            // border-radius: .5rem;
            position: relative;
            z-index: 2;
            text-decoration: none;

            &:hover {
                background: $dark;
                color: $white;
            }
        }
    }
}