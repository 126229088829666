/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
// Font Awesome core compile (Web Fonts-based)
// -------------------------

@import 'functions';
@import 'variables';
@import 'mixins';
@import 'core';
// @import 'sizing';
@import 'fixed-width';
@import 'list';
// @import 'bordered-pulled';
// @import 'animated';
// @import 'rotated-flipped';
// @import 'stacked';
@import 'icons';
@import 'duotone-icons';
// @import 'screen-reader';
@import 'regular';
@import 'duotone';
@import 'brands';
