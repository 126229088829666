.block-free {
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    @extend .typo-rich;

    :first-child {
        margin-top: 0;
    }
}