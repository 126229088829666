.block-download {
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .intro {
        margin-bottom: 2.5rem;

        .title {
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            h2 {
                @extend .typo-h2;
                margin: 0;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
            }
        }
    }

    .elements {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem 2rem;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;
        }

        .element-item {
            position: relative;
            // margin-bottom: 2.5rem;
            display: grid;
            grid-template-columns: 30% 1fr;
            gap: 1rem;

            @include media-breakpoint-down(md) {
                // grid-template-columns: 1fr;
            }

            .image {
                display: block;
                position: relative;
                border: 1px solid $dark;

                @include media-breakpoint-down(md) {
                    // display: none;
                }

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                }

                img {
                    // border: 1px solid $medium;
                }

                &:hover {

                    picture,
                    img {
                        opacity: 1;
                    }
                }
            }

            .text {

                .title,
                .subtitle {
                    color: $color;
                    font-size: 1rem;
                    line-height: 1;
                    margin-bottom: .75rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    display: block;
                    text-decoration: none;
                }
            }

        }
    }

    .files {

        .file {
            display: flex;
            flex-shrink: 0;
            margin-bottom: 1rem;
            font-size: .875rem;
            align-items: center;
            gap: 1rem;
            background: $light;
            border-radius: 1rem;
            padding: .5rem 1rem;
            text-decoration: none;
            font-weight: bold;

            &:hover {
                background: $color;
                color: $white;
                --fa-primary-color: #FFF;
                --fa-secondary-color: #000;
            }

            i {
                font-size: 4rem;
                margin-bottom: 1rem;
            }
        }
    }
}