.post-header {
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .title {
        @extend .typo-h1;
        margin: 0 0 2rem;
    }

    .details {
        margin-bottom: 1.75rem;

        .meta {
            display: flex;
            align-items: center;

            .date {

                display: flex;
                align-items: center;
                font-size: .875rem;
                color: $black;
                text-transform: uppercase;

                i {
                    font-size: 1.5rem;
                    margin-right: .5rem;
                }
            }
        }
    }
}