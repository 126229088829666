.block-case {

    .title {
        margin: 0 0 1.5rem;

        h2 {
            @extend .typo-h2;
            margin: 0 0;
            color: $color;
        }
    }

    @extend .case-grid;
}
