.block-image {
    padding: 3rem 0;
    text-align: center;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .title {
        margin: 0 0 1.5rem;

        h2 {
            @extend .typo-h2;
            margin: 0 0;
        }
    }

    .link {
        margin-top: 1.5rem;
    }

    .image {

        picture,
        img {
            width: 100%;
            object-fit: contain;
            box-shadow: 0;
        }

        &.fullwidth {

            picture,
            img {
                width: 100%;
                max-height: 50vh;

                object-fit: cover;
                box-shadow: 0;
            }
        }
    }
}