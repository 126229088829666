#mobile-menu {

    .offcanvas-body {
        padding: 0;
        background: $light;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .header {
        padding: 1rem;

        .header-wrapper {
            display: flex;
        }

        .close {
            border: 0;
            color: $white;
            background: $dark;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            transition: .15s;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 50%;
            flex-shrink: 0;
            margin: 0 0 0 auto;

            i {
                font-size: 1rem;
                transform: scale(1.25);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .15s;
            }

            &:hover {
                color: $white;
                background: $color;
            }
        }

        .social-links {
            display: flex;
            align-items: center;

            a {
                color: $white;
                background: $color;
                width: 2.5rem;
                height: 2.5rem;
                padding: 0;
                transition: .15s;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 50%;
                flex-shrink: 0;
                margin-right: 1rem;
                text-decoration: none;

                &:hover {
                    color: $white;
                    background: $dark !important;
                }
            }
        }
    }

    .navigation {
        padding: 0 1rem 1rem;

        .mobile-menu {
            padding: 0;
            position: static;
            border-top: 1px solid $light;

            .nav-item {
                color: $dark;
                position: relative;
                border-bottom: 1px solid $light;

                .nav-link {
                    display: flex;
                    font-family: $font;
                    align-items: center;
                    font-size: 1rem;
                    font-weight: bold;
                    line-height: 1rem;
                    color: $dark;
                    position: relative;
                    padding: .75rem .5rem;
                    text-transform: uppercase;
                }

                // Toggles
                .dropdown-toggle {
                    display: flex;
                    margin-left: auto;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    font-size: .875rem;
                    align-items: center;
                    justify-content: center;
                    width: 2.5rem;
                    height: 2.5rem;
                    color: $light;
                    background: $dark;
                    border-radius: .5rem;

                    &:hover {
                        color: $white;
                        background: $color;
                    }

                    i {
                        transition: .15s ease-in-out;
                    }

                    &.show {

                        i {
                            transform: scale(-1);
                        }
                    }
                }

                &.active {

                    .nav-link {
                        color: $color;
                        background: $light;
                    }
                }

                &:hover {

                    .nav-link {
                        color: $color;
                        // background: $light;
                    }
                }

                // Dropdown
                &.dropdown {

                    &.active {
                        color: $dark;
                    }

                    .dropdown-menu {
                        position: static;
                        z-index: 1000;
                        display: none;
                        padding: 0 0 1rem;
                        text-align: left;
                        list-style: none;
                        background-color: $light;
                        // background-clip: padding-box;
                        min-width: 100%;
                        height: 0;
                        border: 0;

                        &.show {
                            height: auto;
                            transform: unset !important;
                            position: relative !important;
                            left: 0 !important;
                            display: flex;
                            border-top: 1px solid $light;
                            flex-wrap: wrap;
                        }

                        .dropdown-item {
                            white-space: normal;
                            font-size: .875rem;
                            padding: 1rem;
                            margin: 1rem 0 0;
                            padding-bottom: .75rem;
                            display: flex;
                            line-height: 1.25;
                            color: $dark;
                            font-weight: bold;
                            background: $white;
                            border-radius: 1rem;
                            // border-bottom: 1px solid rgba($dark, .25);

                            &:first-child {
                                // margin-top: 1.5rem;
                            }

                            &:last-child {
                                // border-bottom: 0;
                                // margin-bottom: 1.5rem;
                                // padding-bottom: 0;
                            }

                            &:hover,
                            &.active,
                            &:active,
                            &:focus {
                                // text-decoration: underline;
                                background: $dark;
                                color: $white;
                            }
                        }

                        .group {
                            padding: 0;
                            page-break-inside: avoid;
                            break-inside: avoid-column;
                            display: block;
                            margin: 1rem 0 0;
                            background: $white;
                            border-radius: 1rem;
                            padding: 1rem;
                            width: 100%;

                            .group-parent {
                                @extend .typo-h5;
                                font-size: 1rem;
                                text-decoration: none;
                                color: $dark;
                                margin-bottom: 1rem;
                                display: block;
                                font-weight: bold;

                                text-transform: uppercase;

                                &:hover,
                                &.active,
                                &:active,
                                &:focus {
                                    // text-decoration: underline;
                                    background: none;
                                    color: $color;
                                }
                            }

                            .group-pages {
                                display: flex;
                                flex-direction: column;

                                .group-page {
                                    white-space: normal;
                                    font-size: .875rem;
                                    padding: 0;
                                    margin-bottom: .75rem;
                                    padding-bottom: .75rem;
                                    display: flex;
                                    line-height: 1.25;
                                    color: $dark;
                                    // font-weight: bold;
                                    text-decoration: none;
                                    // text-transform: uppercase;
                                    border-bottom: 1px solid rgba($dark, .25);

                                    &:last-child {
                                        border-bottom: 0;
                                        margin-bottom: 0;
                                        padding-bottom: 0;
                                    }

                                    &:hover,
                                    &.active,
                                    &:active,
                                    &:focus {
                                        // text-decoration: underline;
                                        background: none;
                                        color: $color;
                                    }
                                }

                            }
                        }

                    }

                    &.show {
                        .dropdown-menu {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }

                &.nav-btn {
                    color: $white;
                    border-radius: .5rem;

                    .nav-link {
                        color: $white;
                        background-color: $color;
                        border-radius: .5rem;
                    }

                    .dropdown-toggle {
                        background: transparent;
                        font-size: 1.125rem;
                    }

                    &:hover {

                        .nav-link {
                            color: $white;
                            background-color: $dark;
                            // background: $light;
                        }

                        .dropdown-toggle {
                            background: transparent;
                            color: $white;
                        }
                    }

                    &.active {

                        .nav-link {
                            color: $white;
                            background-color: $dark;
                            // background: $light;
                        }

                        .dropdown-toggle {
                            background: transparent;
                            color: $white;
                        }

                    }
                }
            }
        }
    }
}