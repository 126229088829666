body.search-results .current_page_parent {
    color: $dark !important;
}

.search-results-list {
    padding: 4.5rem 0;
    background: $white;

    @include media-breakpoint-down(md) {
        padding: 2.5rem 0;
    }

    .results-list {
        display: grid;
        gap: 2rem;

        .search-result {
            display: grid;
            grid-template-columns: 10rem 1fr;

            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
            }

            .image {
                display: block;
                height: 100%;

                picture,
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .content {
                padding: .75rem 1.5rem;
                background: $light;

                .category {
                    @extend .typo-label;
                    font-size: 1rem;
                    text-transform: uppercase;

                    @include media-breakpoint-down(md) {
                        font-size: .875rem;
                    }
                }

                .title {
                    @extend .typo-h4;
                }

                .summary {
                    font-size: 1rem;
                    margin: 1rem 0;

                    @include media-breakpoint-down(md) {
                        font-size: .875rem;
                        margin: 1rem 0;
                    }
                }

            }

            &.news {
                margin-bottom: 0;
            }
        }
    }

    .no-results {
        p {
            text-align: center;
            margin: 0;

            @include media-breakpoint-down(sm) {
                margin-bottom: 2rem;
            }
        }

        .search-form {
            display: flex;
            margin: 0;

            .form-control {
                border: 1px solid $dark;
                height: 3rem;
                font-size: 1rem;
                padding: .75rem 1rem;
            }

            .btn {
                border: 0;
                color: $white;
                background: $color;
                min-width: 3rem;
                transition: .25s;
                margin-left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 0 .25rem .25rem 0;

                i {
                    font-size: 1.25rem;
                    margin: 0;
                }

                &:hover {
                    color: $white;
                    background: $dark;
                }
            }
        }

    }

}