.block-inbound {
    overflow: hidden;
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .cta {
        background-color: $color;
        border-radius: 1rem;
        color: $white;
        padding: 2rem;

        .title {
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            h2 {
                @extend .typo-h2;
                margin: 0;
                color: $white !important;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
                color: $white !important;
            }
        }

        .subtitle {
            @extend .typo-label;
        }

        .text {
            @extend .typo-rich;
        }

        .link {
            margin-top: 1.5rem;

            @include media-breakpoint-down(md) {
                margin-top: 1rem;
            }
        }

		@include media-breakpoint-down(md) {
			.vert {
				width: 50%;
                margin: 0 auto;
			}
		}
    }

}