.form-select {
    border-radius: .25rem;
  border: 1px solid $dark;

  &:focus, &:active {
    border-color: $dark;
  }
}

.grecaptcha-badge {
  display: none;
}