.block-timeline {
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .timeline-slider {
        display: block;
        overflow: hidden;

        .year {
            font-size: 1.75rem;
            margin: 0 0 1rem;
            font-family: $font;
            line-height: 1.25;
            font-weight: bold;
            color: $color;
            // text-transform: uppercase;
            border-bottom: 1px solid $color;

            @include media-breakpoint-down(md) {
                font-size: 1.5rem;
            }
        }

        .text {
            @extend .typo-rich;
            padding-right: 2rem;

        }
    }

    .timeline-pagination {
        bottom: auto;
        text-align: center;
        margin-top: 3rem;
    }
}