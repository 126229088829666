.swiper-pagination {
    position: relative;
    bottom: auto;
    text-align: right;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    margin-top: 1.5rem;

    .swiper-pagination-bullet {
        width: .875rem;
        height: .875rem;
        display: inline-block;
        background: $dark;
        opacity: 1;
        border: 0;
        margin: 0 0 0 .5rem !important;
        border-radius: 0;

        &.swiper-pagination-bullet-active {
            background: $color;
        }
    }

}