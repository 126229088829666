.block-contact {
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .intro {
        // text-align: center;
        margin-bottom: 2.5rem;

        .title {
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            h2 {
                @extend .typo-h2;
                margin: 0;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
            }
        }

        .subtitle {
            @extend .typo-label;
        }
    }

    .wp-form,
    .custom-form {

        form {

            .warning {
                font-size: .75rem;
                margin-top: .25rem;
                color: red;
            }

            .form-group {

                p {
                    font-size: .875rem;
                }

                label {
                    margin-bottom: .25rem;
                }

                .form-control {
                    // background: transparent;
                    border-color: rgba($dark, .25);
                    border-radius: .25rem;
                }

                textarea.form-control {
                    height: auto;
                }

                .form-select {
                    // background: transparent;
                    border-color: rgba($dark, .25);
                    border-radius: .25rem;
                }

                .form-check {
                    // display: flex;
                    // align-items: center;
                    // gap: .5rem;

                    .form-check-input {
                        margin-top: .125rem
                    }
                }

                [data-name=Request] {
                    margin-top: .25rem;

                    .form-check-label {
                        font-weight: bold;
                    }
                }

                a {
                    color: $color;
                    font-weight: bold;
                }
            }

            .send-group {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .button-wrapper {
                    position: relative;
                }

                span {
                    order: 1;
                }

                .btn {
                    order: 2;
                    border: 0;

                    &:disabled {
                        background: rgba($dark, .5);
                        pointer-events: none;
                    }
                }
            }

            .extra {
                margin-bottom: 2rem;
                border: 1px solid $color;
                padding: 1rem;

                span {
                    font-size: 1rem;
                    line-height: 1.25;
                    display: block;
                }

                a {
                    margin-top: 1rem;
                    font-weight: bold;
                }
            }

            // CF7 overrides
            br {
                display: none !important;
            }

            .wpcf7-not-valid-tip {
                font-size: .75rem;
                margin-top: .25rem;
                color: red;
            }

            .wpcf7-acceptance+.wpcf7-not-valid-tip {
                margin-top: 0;
                margin-bottom: 1rem;
            }

            .wpcf7-form-control-wrap {
                display: block;
            }

            .wpcf7-list-item {
                margin-left: 1rem;

                &:first-child {
                    margin-left: 0;
                }
            }

            .wpcf7-response-output {
                border-color: red !important;
                margin: 0 !important;
                padding: .25rem .5rem !important;
            }

            // [data-class="wpcf7cf_group"] {
            //     width: 100%;

            //     +.row {
            //         margin-top: 1rem;
            //     }
            // }

            .wpcf7-spinner {
                position: absolute;
                width: 100%;
                height: 100%;
                border: none;
                border-radius: .25rem;
                margin: 0;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $dark;

                &:before {
                    display: none;
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background-color: $color;
                    opacity: 0.75;
                    width: 24px;
                    height: 24px;
                    border: none;
                    border-radius: 100%;
                    padding: 0;
                    margin: 0 24px;
                    transform: scaleX(1);
                    animation: pulse 3s infinite;
                }


                @keyframes pulse {
                    0% {
                        transform: scale(0.75);
                    }

                    50% {
                        transform: scale(1);
                    }

                    100% {
                        transform: scale(0.75);
                    }
                }

            }

        }
    }

    .hubspot-form {
        margin-top: 1rem;

        form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;

            // Hubspot overrides
            .hs-fieldtype-text,
            .hs-fieldtype-textarea,
            .hs-fieldtype-phonenumber,
            .hs-fieldtype-select {
                .input {
                    @extend .form-floating;
                }
            }

            .hs-fieldtype-text,
            .hs-fieldtype-phonenumber {
                &>label {
                    display: none;
                    margin-bottom: .5rem;
                }

                .input {
                    input {
                        @extend .form-control;
                        border-color: rgba($dark, .25);
                        border-radius: .25rem;
                    }

                    label {
                        display: block;
                    }
                }
            }

            .hs-fieldtype-textarea {
                &>label {
                    display: none;
                    margin-bottom: .5rem;
                }

                &.hs-message {
                    grid-column: 1 / 3;
                }

                .input {
                    textarea {
                        @extend .form-control;
                        height: auto;
                        border-color: rgba($dark, .25);
                        border-radius: .25rem;
                    }

                    label {
                        display: block;
                    }
                }
            }

            .hs-fieldtype-select {
                &>label {
                    display: none;
                    margin-bottom: .5rem;
                }

                .input {
                    select {
                        @extend .form-select;
                        border-color: rgba($dark, .25);
                        border-radius: .25rem;
                    }

                    label {
                        display: block;
                    }
                }
            }

            .hs-fieldtype-booleancheckbox {
                grid-column: 1 / 3;

                .inputs-list {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    .hs-form-booleancheckbox-display {
                        @extend .form-check;

                        input {
                            @extend .form-check-input;
                        }

                        span {
                            font-size: .875rem;
                            @extend .form-check-label;
                        }
                    }
                }
            }

            .hs-fieldtype-radio {
                grid-column: 1 / 3;

                .inputs-list {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: flex;
                    gap: 1rem;
                    margin-top: .5rem;

                    .hs-form-radio-display {
                        @extend .form-check;

                        input {
                            @extend .form-check-input;
                        }

                        span {
                            font-size: .875rem;
                            @extend .form-check-label;
                        }
                    }
                }
            }

            .hs-error-msgs {
                list-style: none;
                font-size: .75rem;
                margin: .25rem 0 0;
                padding: 0;
                color: red;
            }

            a {
                color: $color;
                font-weight: bold;
            }

            .hs-submit {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                grid-column: 1 / 3;

                input {
                    @extend .btn;
                    border: 0;
                }
            }
        }

    }

    .privacy-modal {
        .modal-dialog {
            max-width: 50rem;

            .modal-content {
                position: relative;

                h4 {
                    margin: 0;
                    padding-right: 2rem;
                }

                .close {
                    width: 2rem;
                    height: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.25rem;
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    border: 0;
                    background: none;
                }
            }
        }
    }
}

.grecaptcha-badge {
    display: none !important;
}