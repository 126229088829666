// DEV NOTES
// Dropdown menu visibility is deferred to hover intent script and class .visible

#main-menu {
    padding: 0 1rem;

    .menu-desktop {
        // position: relative;
        align-items: center;
    }

    .nav-item {
        padding: 0;
        // margin: 2.25rem .75rem 0;

        .nav-link {
            display: flex;
            font-family: $font;
            align-items: center;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1rem;
            position: relative;
            // padding: 0 0 2.25rem;
            margin: 0;
            color: $dark;

            @include media-breakpoint-down(xxl) {
                font-size: .875rem;
            }

            &:hover,
            &.active {
                color: $color;
            }
        }

        .btn-icon {
            margin-left: .5rem;
            font-size: 1rem;
            height: 2.5rem;
            padding: 0 .75rem;

            @include media-breakpoint-down(xxl) {
                font-size: .875rem;

                i {
                    display: none;
                }

            }

            @include media-breakpoint-down(xl) {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 .5rem;
                width: 2.5rem;

                i {
                    display: block;
                    margin-right: 0;
                }

                span {
                    display: none;
                }

            }
        }

        &.active {
            .nav-link {
                color: $color;
            }
        }

        &.dropdown {
            position: relative;
            // margin: 2.25rem .75rem 0;

            .box {
                padding: 1rem;
                background: $color;
                display: flex;
                flex-direction: column;
                border-radius: 1rem;
                color: $white;

                @include media-breakpoint-down(xl) {
                    display: none;
                }

                .title {
                    white-space: normal;
                    font-size: 1rem;
                    margin-bottom: .75rem;
                    line-height: 1.25;
                    font-weight: bold;
                    text-transform: uppercase;

                }

                .description {
                    white-space: normal;
                    font-size: .8125rem;
                    margin-bottom: .75rem;
                    line-height: 1.25;
                }

                .image {
                    margin-bottom: .75rem;
                }

                .link {
                    color: $white;

                    .btn {
                        &:hover {
                            background: $color;
                        }
                    }
                }

                &:hover {
                    background: $dark;

                    .link {
                        .btn {
                            background: $color;
                            color: $white;
                        }
                    }
                }
            }

            // Megamenu
            &.megamenu {
                position: static;

                .mega {
                    // display: block !important;
                    left: 50%;
                    top: 100%;
                    padding: 0;
                    min-height: 20rem;
                    // flex-direction: row;
                    background: none;
                    border: 0;
                    transform: translateX(-50%);
                    width: 100vw;

                    @include media-breakpoint-up(xl) {
                        // width: 130%;
                    }

                    @include media-breakpoint-up(xxl) {
                        // width: 150%;
                    }

                    .wrapper {
                        // border-top: 1px solid rgba($dark, .25);
                        // border-bottom: 1px solid rgba($dark, .25);
                        // border-top: 0;
                        // width: 100vw;
                        // margin: 0 10%;
                        background: $light;
                        display: flex;
                        padding: 1rem 0;

                        &:after {
                            content: "";
                            display: block;
                            height: 100vh;
                            width: 100vw;
                            background-color: rgba($dark, .5);
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: -1;
                            pointer-events: none;
                        }

                        .pages {
                            display: block;
                            columns: 3;
                            column-gap: 1rem;
                            // display: grid;
                            // grid-template-columns: repeat(3, 1fr);
                            // grid-template-rows: repeat(var(--count), auto);
                            gap: 0 1rem;
                            // grid-auto-flow: column;

                            .group {
                                height: auto;
                                margin-bottom: 1rem;
                                page-break-inside: avoid;
                                break-inside: avoid-column;
                                display: inline-block;
                                width: 100%;
                                padding: 1rem;
                                background: $white;
                                border-radius: 1rem;

                                .group-parent {
                                    white-space: normal;
                                    font-size: .875rem;
                                    text-decoration: none;
                                    display: flex;
                                    flex-direction: column;
                                    // align-items: center;
                                    justify-content: center;
                                    line-height: 1.25;
                                    color: $dark;
                                    font-weight: bold;
                                    text-transform: uppercase;

                                    @include media-breakpoint-down(xl) {
                                        font-size: .875rem;
                                    }

                                    &:hover,
                                    &.active,
                                    &:active,
                                    &:focus {
                                        // text-decoration: underline;
                                        background: none;
                                        color: $color;
                                    }
                                }

                                .group-pages {
                                    margin-top: .75rem;
                                    padding: .5rem 0 0;
                                    border-top: 1px solid rgba($dark, .25);
                                    display: flex;
                                    flex-direction: column;

                                    .group-page {
                                        white-space: normal;
                                        font-size: .8125rem;
                                        margin-bottom: .5rem;
                                        line-height: 1.25;
                                        color: $dark;
                                        text-decoration: none;

                                        @include media-breakpoint-down(xl) {
                                            font-size: .75rem;
                                        }

                                        &:hover,
                                        &.active,
                                        &:active,
                                        &:focus {
                                            // text-decoration: underline;
                                            background: none;
                                            color: $color;
                                            font-weight: bold;
                                        }

                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }

                                }
                            }

                        }
                    }
                }
            }

            // Type 1
            &.type-1,
            &.type-2 {
                position: static;

                .dropdown-menu {

                    // display: flex !important;
                    left: 50%;
                    top: 100%;
                    padding: 0;
                    // min-height: 20rem;
                    flex-direction: row;
                    background: none;
                    border: 0;
                    transform: translateX(-50%);

                    // position: absolute;
                    // top: 100%;
                    // left: 0;
                    // z-index: 1000;
                    // float: left;
                    // padding: 1.5rem 1.5rem 0;
                    // margin: 0;
                    // text-align: left;
                    // list-style: none;
                    // background-color: $white;
                    // background-clip: padding-box;
                    // border: 1px solid rgba($dark, .25);
                    // border-top: 0;
                    // display: none;
                    // min-width: 16rem;
                    // text-transform: uppercase;



                    .wrapper {
                        width: 100vw;
                        background: $light;
                        display: flex;
                        padding: 1rem 0;

                        &:after {
                            content: "";
                            display: block;
                            height: 100vh;
                            width: 100vw;
                            background-color: rgba($dark, .5);
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: -1;
                            pointer-events: none;
                        }

                        .pages {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            gap: 1rem;

                            .dropdown-item {
                                white-space: normal;
                                font-size: .875rem;
                                padding: 0;
                                // margin-bottom: .75rem;
                                // padding-bottom: .75rem;
                                display: flex;
                                line-height: 1.25;
                                color: $dark;
                                background: white;
                                padding: 1rem;
                                border-radius: 1rem;
                                background: $white;
                                font-weight: bold;
                                // border-bottom: 1px solid rgba($dark, .25);

                                @include media-breakpoint-down(xl) {
                                    font-size: .875rem;
                                }

                                &:last-child {
                                    border-bottom: 0;
                                }

                                &:hover,
                                &.active,
                                &:active,
                                &:focus {
                                    // text-decoration: underline;
                                    background: $dark;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }

            // Type 3 - Box List
            &.type-3 {
                position: static;

                .dropdown-menu {
                    // display: flex !important;
                    left: 50%;
                    top: 100%;
                    padding: 0;
                    // min-height: 20rem;
                    flex-direction: row;
                    background: none;
                    border: 0;
                    transform: translateX(-50%);
                    width: 100%;

                    .wrapper {
                        background: $light;
                        display: flex;
                        padding: 1rem 0;

                        &:after {
                            content: "";
                            display: block;
                            height: 100vh;
                            width: 100vw;
                            background-color: rgba($dark, .5);
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: -1;
                            pointer-events: none;
                        }

                        .sub-pages {
                            padding: 0;
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            gap: 1rem;

                            .dropdown-item {
                                white-space: normal;
                                font-size: .875rem;
                                padding: 1rem;
                                // margin-bottom: .75rem;
                                // padding-bottom: .75rem;
                                display: flex;
                                flex-direction: column;
                                background: $white;
                                border-radius: 1rem;
                                // align-items: center;
                                // justify-content: center;
                                line-height: 1.25;
                                color: $dark;
                                font-weight: bold;
                                text-transform: uppercase;
                                // border-bottom: 1px solid rgba($dark, .25);

                                @include media-breakpoint-down(xl) {
                                    font-size: .875rem;
                                }

                                .description {
                                    white-space: normal;
                                    font-size: 0.8125rem;
                                    margin-top: .5rem;
                                    line-height: 1.25;
                                    text-transform: none;
                                    font-weight: normal;

                                    @include media-breakpoint-down(xl) {
                                        font-size: .75rem;
                                    }
                                }

                                &:last-child {
                                    border-bottom: 0;
                                }

                                &:hover,
                                &.active,
                                &:active,
                                &:focus {
                                    // text-decoration: underline;
                                    background: $dark;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }

            &:not(.megamenu):last-child {
                .dropdown-menu {
                    right: 0;
                    left: auto;
                }
            }
        }

        // Hover intent
        &.visible {
            .dropdown-menu {
                display: grid;
            }
        }

        // Tablet fallback
        &.touch-device:hover {
            .dropdown-menu {
                display: grid;
            }
        }

    }
}