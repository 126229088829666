.homepage-header {
    margin: 3rem 0;

    h1 {
        text-align: center;
        font-size: 3rem;
        font-family: $font;
        line-height: 1.25;
        color: $color;
        font-weight: bold;

        strong {
            color: $color;
        }

        @include media-breakpoint-down(lg) {
            font-size: 2rem;
        }

        @include media-breakpoint-down(md) {
            font-size: 1.75rem;
        }

        @include media-breakpoint-down(sm) {
            font-size: 1.625rem;
        }

    }
}