.block-quote {
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .quote {
        border-left: 1px solid $color;   
        padding: 1rem 0 1rem 2.5rem; 
        display: block;

        @include media-breakpoint-down(md) {
            padding-left: 1.5rem;
        }

        .text {
            font-size: 1.25rem;
            line-height: 1.5;
            font-style: italic;

            @include media-breakpoint-down(lg) {
                font-size: 1.125rem;
            }

            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }

        .cite {
            text-align: left;
            font-size: 1.125rem;
            line-height: 1.5;
            margin-top: .875rem;
        }
    }
}