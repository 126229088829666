.footer {
    background: $dark;
    color: $white;
    // margin-top: 3rem;

    .quicklinks {
        background: $white;
        padding-bottom: 3rem;

        .quicklink {
            margin-bottom: 1rem;

            .link {
                background: $color;
                color: $white;
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 1rem;
                border-radius: 1rem;
                text-decoration: none;
                transition: .15s ease-in-out;

                .title {
                    font-size: 1.25rem;
                    line-height: 1.25;
                    display: block;
                    margin-bottom: .5rem;
                    font-weight: bold;
                }

                .text {
                    margin-bottom: 1rem;
                }

                .btn {
                    margin-top: auto;
                    margin-left: auto;

                    &:hover {
                        background: $white;
                        color: $dark;
                    }
                }

                &:hover {
                    background: $dark;

                    .btn {
                        background: $color;
                        color: $white;
                    }
                }
            }
        }
    }

    .infos {
        padding: 4rem 0;
        line-height: 1.5;

        h4 {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 1.25rem;
        }

        .company {

            line-height: 1.3;
            // display: flex;
            // align-items: center;

            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }

            .logo {
                display: block;
                // border-right: 1px solid $white;
                margin-bottom: 1rem;
                flex-shrink: 0;

                @include media-breakpoint-down(lg) {

                    // padding: 1.5rem 0 0;
                    border: 0;
                }

                .brand {
                    height: 4rem;
                    width: auto;
                }
            }

            .text {
                // padding-left: 1rem;

                @include media-breakpoint-down(lg) {
                    margin-bottom: 3rem;
                }
            }
        }

        .address {
            line-height: 1.3;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }
        }

        .contacts {
            display: flex;
            flex-direction: column;
            line-height: 1.3;

            a {
                display: inline-block;
                text-decoration: none;
                font-weight: bold;

                i {
                    width: 1.25rem;
                }
            }
        }
    }

    .legal-infos {
        padding: 0 0 4rem;
        // text-align: right;
        font-size: .875rem;
        line-height: 1.5;

        a {
            font-weight: bold;
            &:hover {
                color: $color;
            }
        }

        @include media-breakpoint-down(lg) {
            text-align: left;
        }
    }
}