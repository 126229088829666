.block-icons {

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    padding: 3rem 0;

    .intro {
        text-align: center;
        margin-bottom: 2.5rem;

        .title {

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            margin: 0 0 1.5rem;

            h2 {
                @extend .typo-h2;
                margin: 0;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
            }
        }

        .subtitle {
            @extend .typo-label;
        }
    }

    .icons {
        text-align: center;

        .icon {
            // padding: 0 2rem;
            margin-bottom: 2rem;

            &.bordered {
                padding: 1rem;
                box-shadow: 0 0 .5rem rgba($dark, .15);
                border-radius: .75rem;
            }

            i {
                font-size: 4rem;
            }

            .title {
                font-size: 1.125rem;
                font-weight: bold;
                margin: 1rem 0;
            }

            .text {

                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                }

                font-size: 1.0625rem;
                line-height: 1.4;
            }
        }
    }

    &.with-bg {
        position: relative;
        margin-bottom: 3rem;
        background: $light;
        padding-bottom: 0;
        overflow: clip;
    }
}

.block-logos {
    .icons {

        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0 2rem;
    }
}