.block-pages {
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .intro {
        text-align: center;
        margin-bottom: 2.5rem;

        .title {
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            h2 {
                @extend .typo-h2;
                margin: 0;
            }
        }

        .subtitle {
            @extend .typo-label;
            color: $color;
        }

        .text {
            @extend .typo-rich;
        }
    }

    .pages {
        .page {
            display: block;
            border-radius: .75rem;
            padding: 1rem;
            box-shadow: 0 0 .5rem rgba($dark, .15);
            text-decoration: none;
            height: 100%;
            text-align: center;

            &:hover,
            &:active,
            &:focus {
                box-shadow: 0 0 .5rem rgba($dark, .25) !important;
                color: $black;
            }

            .image {
                margin-bottom: 1.5rem;
            }

            .icon {
                margin-bottom: 1.5rem;

                i {
                    font-size: 6rem;
                }
            }

            .title {
                font-size: 1rem;
                line-height: 1.1;
                margin: 0 0 1rem;
                font-weight: bold;
                color: $color;
                text-transform: uppercase;
            }

            .summary {
                font-size: 1rem;
                line-height: 1.4;
                margin: 0;

                p {
                    margin: 0;
                }
            }
        }
    }
}