.block-three-columns {
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .heading {
        .title {
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            h2 {
                @extend .typo-h2;
                margin: 0;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
            }
        }

        .link {
            margin-top: 1.5rem;
            font-size: 1rem;

            @include media-breakpoint-down(md) {
                margin-top: 1rem;
            }
        }

        .image {

            @include media-breakpoint-down(lg) {
                max-width: 36rem;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        .text {
            @extend .typo-rich;
            column-count: 2;
            column-gap: 2rem;

            // * {
            //     page-break-inside: avoid;
            //     break-inside: avoid-column;
            // }

            @include media-breakpoint-down(md) {
                font-size: 1rem;
                margin-top: 1.5rem;
                column-count: 1;
            }

        }
    }

    &.with-bg {
        position: relative;
        background: $light;
        overflow: hidden;
        margin-bottom: 3rem;
        overflow: clip;
    }
}