.block-two-columns {
	padding: 3rem 0;

	.content {
		// display: flex;
		// flex-direction: column;

		.title {

			@include media-breakpoint-down(lg) {
				margin: 0 0 1rem;
			}
			margin: 0 0 1.5rem;

			h2 {
				@extend .typo-h2;
				margin: 0;
			}

			h3 {
				@extend .typo-h3;
				margin: 0;
			}
		}

		.subtitle {
			@extend .typo-label;
		}

		.text {
			@extend .typo-rich;

			@include media-breakpoint-down(lg) {
				margin-bottom: 1.5rem;
			}
		}

		.link {
			margin-top: 1rem;

			a {
				color: $color;
			}
		}

		.hubspot-form {
			margin-top: 1rem;

			form {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1rem;

				// Hubspot overrides
				.hs-fieldtype-text,
				.hs-fieldtype-textarea,
				.hs-fieldtype-phonenumber,
				.hs-fieldtype-select {
					.input {
						@extend .form-floating;
					}
				}

				.hs-fieldtype-text,
				.hs-fieldtype-phonenumber {
					&>label {
						display: none;
						margin-bottom: 0.5rem;
					}

					.input {
						input {
							@extend .form-control;
							border-color: rgba($dark, 0.25);
							border-radius: 0.25rem;
						}

						label {
							display: block;
						}
					}
				}

				.hs-fieldtype-textarea {
					&>label {
						display: none;
						margin-bottom: 0.5rem;
					}

					&.hs-message {
						grid-column: 1 / 3;
					}

					.input {
						textarea {
							@extend .form-control;
							border-color: rgba($dark, 0.25);
							border-radius: 0.25rem;
							height: auto;
						}

						label {
							display: block;
						}
					}
				}

				.hs-fieldtype-select {
					&>label {
						display: none;
						margin-bottom: 0.5rem;
					}

					.input {
						select {
							@extend .form-select;
							border-color: rgba($dark, 0.25);
							border-radius: 0.25rem;
						}

						label {
							display: block;
						}
					}
				}

				.hs-fieldtype-booleancheckbox {
					grid-column: 1 / 3;

					.inputs-list {
						margin: 0;
						padding: 0;
						list-style: none;

						.hs-form-booleancheckbox-display {

							@extend .form-check;

							input {
								@extend .form-check-input;
							}

							span {
								@extend .form-check-label;
								font-size: 0.875rem;
							}
						}
					}
				}

				.hs-fieldtype-radio {
					grid-column: 1 / 3;

					.inputs-list {
						display: flex;
						gap: 1rem;
						margin: 0;
						margin-top: 0.5rem;
						padding: 0;
						list-style: none;

						.hs-form-radio-display {

							@extend .form-check;

							input {
								@extend .form-check-input;
							}

							span {
								@extend .form-check-label;
								font-size: 0.875rem;
							}
						}
					}
				}

				.hs-error-msgs {
					margin: 0.25rem 0 0;
					padding: 0;
					color: red;
					font-size: 0.75rem;
					list-style: none;
				}

				a {
					color: $color;
					font-weight: bold;
				}

				.hs-submit {
					display: flex;
					grid-column: 1 / 3;
					justify-content: flex-end;
					align-items: center;

					input {
						@extend .btn;
						border: 0;
					}
				}
			}
		}

		.wp-form,
		.custom-form {
			form {
				.warning {
					margin-top: 0.25rem;
					color: red;
					font-size: 0.75rem;
				}

				.form-group {
					p {
						font-size: 0.875rem;
					}

					label {
						margin-bottom: 0.25rem;
					}

					.form-control {
						// background: transparent;
						border-color: rgba($dark, 0.25);
						border-radius: 0.25rem;
					}

					textarea.form-control {
						height: auto;
					}

					.form-select {
						// background: transparent;
						border-color: rgba($dark, 0.25);
						border-radius: 0.25rem;
					}

					.form-check {
						// display: flex;
						// align-items: center;
						// gap: .5rem;

						.form-check-input {
							margin-top: 0.125rem;
						}
					}

					[data-name="Request"] {
						margin-top: 0.25rem;

						.form-check-label {
							font-weight: bold;
						}
					}

					a {
						color: $color;
						font-weight: bold;
					}
				}

				.send-group {
					display: flex;
					justify-content: flex-end;
					align-items: center;

					.button-wrapper {
						position: relative;
					}

					span {
						order: 1;
					}

					.btn {
						order: 2;
						border: 0;

						&:disabled {
							background: rgba($dark, 0.5);
							pointer-events: none;
						}
					}
				}

				.extra {
					margin-bottom: 2rem;
					border: 1px solid $color;
					padding: 1rem;

					span {
						display: block;
						font-size: 1rem;
						line-height: 1.25;
					}

					a {
						margin-top: 1rem;
						font-weight: bold;
					}
				}

				// CF7 overrides
				br {
					display: none !important;
				}

				.wpcf7-not-valid-tip {
					margin-top: 0.25rem;
					color: red;
					font-size: 0.75rem;
				}

				.wpcf7-acceptance+.wpcf7-not-valid-tip {
					margin-top: 0;
					margin-bottom: 1rem;
				}

				.wpcf7-form-control-wrap {
					display: block;
				}

				.wpcf7-list-item {
					margin-left: 1rem;

					&:first-child {
						margin-left: 0;
					}
				}

				.wpcf7-response-output {
					margin: 0 !important;
					border-color: red !important;
					padding: 0.25rem 0.5rem !important;
				}

				// [data-class="wpcf7cf_group"] {
				//     width: 100%;

				//     +.row {
				//         margin-top: 1rem;
				//     }
				// }

				.wpcf7-spinner {

					@keyframes pulse {
						0% {
							transform: scale(0.75);
						}

						50% {
							transform: scale(1);
						}

						100% {
							transform: scale(0.75);
						}
					}
					display: flex;
					position: absolute;
					top: 0;
					left: 0;
					justify-content: center;
					align-items: center;
					margin: 0;
					border: none;
					border-radius: 0.25rem;
					background-color: $dark;
					width: 100%;
					height: 100%;

					&:before {
						display: none;
					}

					&:after {
						display: inline-block;
						transform: scaleX(1);
						opacity: 0.75;
						animation: pulse 3s infinite;
						margin: 0 24px;
						border: none;
						border-radius: 100%;
						background-color: $color;
						padding: 0;
						width: 24px;
						height: 24px;
						content: "";
					}
				}
			}
		}
	}

	.media {

		@include media-breakpoint-down(lg) {
			.vert {
				margin: 0 auto;
				width: 50%;
			}
		}
		z-index: 1;

		.image-wrapper {
			display: block;
			box-shadow: 0 0 0.5rem rgba($dark, 0.15);
			border-radius: 1rem;
			background: $light;
			// border: 1px solid rgba($dark, .5);
			overflow: hidden;

			picture,
			img {
				width: 100%;
				object-fit: contain;
				// mix-blend-mode: multiply;
				// transition: $trans-start;

				&:hover {
					// transform: $trans-end;
					// filter: $filter;
				}
			}
		}

		a.image-wrapper {

			&:hover,
			&:active,
			&:focus {
				box-shadow: 0 0 0.5rem rgba($dark, 0.25) !important;
			}
		}

		.col-slider {
			display: block;
			overflow: hidden;
		}

		.dual-image {
			.label {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				border-radius: 0.25rem;
				background-color: $color;
				padding: 0.5rem 1rem;
				color: $white;
				font-size: 0.875rem;
				line-height: 1.25;
			}

			.image {
				position: relative;
				padding-right: 5rem;
				// overflow: hidden;
			}

			.image-alt {
				position: relative;
				margin-top: -3rem;
				padding-left: 5rem;

				.label {
					top: auto;
					right: 0;
					bottom: 0;
					left: auto;
				}
			}
		}

		.hotspot-image {
			.wrap_svl_center {
				display: flex;
			}
		}
	}

	&.with-bg {

		@include media-breakpoint-down(lg) {
			margin-bottom: 2rem;
		}
		position: relative;
		margin-bottom: 3rem;
		background: $light;
		padding-bottom: 0;
		overflow: hidden;

		.content {
			margin-bottom: 1rem;
		}

		.media {
			position: relative;
			align-self: flex-end;

			&:before {

				@include media-breakpoint-down(lg) {
					display: none;
				}
				display: block;
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				z-index: -1;
				background: $dark;
				width: 100vw;
				height: 100vh;
				content: "";
			}

			.image-wrapper {

				@include media-breakpoint-down(lg) {
					margin-bottom: 1rem;
					border-radius: 1rem;
				}
				box-shadow: none;
				border-radius: 1rem 1rem 0 0;
				overflow: hidden;
			}

			iframe {
				max-width: 100%;
			}
		}

		&.media-right {
			.media {
				position: relative;

				&:before {
					left: 50%;
				}
			}
		}

		&.media-left {
			.media {
				position: relative;

				&:before {
					right: 50%;
				}
			}
		}
	}

	&.media-right {
		.content {

			@include media-breakpoint-down(lg) {
				padding-right: 1rem;
			}

			order: 1;
			padding-right: 2rem;
		}

		.media,
		.video {
			order: 2;
		}
	}

	&.media-left {
		.media {
			order: 1;
		}

		.content {

			@include media-breakpoint-down(lg) {
				padding-left: 1rem;
			}

			order: 2;
			padding-left: 2rem;
		}
	}
	

	@include media-breakpoint-down(lg) {
		padding: 2rem 0;
	}

	@include media-breakpoint-down(lg) {

		&.media-right,
		&.media-left {
			.media {
				order: 2;
				// margin-bottom: 2rem;
			}

			.content {
				order: 1;

				.link {
					margin-bottom: 1rem;
				}
			}
		}
	}
}