.white-space+.white-space {
    padding-top: 0;
}

.with-bg+.white-space {
    margin-top: -3rem;

    @include media-breakpoint-down(md) {
    margin-top: -2em;
    }
}

// .accordion-item+.accordion-item {
//     margin-top: calc(-4rem - 1px);

//     @include media-breakpoint-down(lg) {
//         margin-top: calc(-3rem - 1px);
//     }

//     // @include media-breakpoint-down(md) {
//     //     margin-top: calc(-2rem - 1px);
//     // }
// }