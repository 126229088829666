.homepage-visual {
    position: relative;

    .homepage-slider {
        max-width: 100vw;

        .slide {
            display: flex;
            flex-direction: column;
            height: auto;
            max-height: calc(100vh - 8.5rem);

            video {
                pointer-events: none;
                object-fit: cover;
                object-position: center;
                max-height: calc(100vh - 8.5rem);
            }

            .image {
                height: 100%;
                position: relative;
                display: flex;

                picture,
                img {
                    width: 100vw;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    max-width: none;
                }

            }

            .content {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                padding: 0 6rem;
                display: flex;
                flex-direction: column;
                text-align: center;
                z-index: 2;
                background: rgba($black, .25);

                @include media-breakpoint-down(md) {
                    padding: 0 2rem;
                }

                .wrapper {
                    position: relative;
                    height: calc(100% - 2rem);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: $white;
                    font-family: $font;

                    .title {
                        font-weight: bold;
                        font-size: 5rem;
                        line-height: 1.25;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        text-transform: uppercase;

                        @include media-breakpoint-down(lg) {
                            font-size: 3.5rem;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 3rem;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 2.5rem;
                        }
                    }

                    .text {
                        font-size: 1.875rem;
                        margin-top: 2rem;
                        position: relative;
                        border: 0;

                        @include media-breakpoint-down(lg) {
                            font-size: 1.75rem;
                        }

                        @include media-breakpoint-down(md) {
                            margin-top: 1.5rem;
                            font-size: 1.5rem;
                        }

                        @include media-breakpoint-down(sm) {
                            margin-top: 1rem;
                            font-size: 1.25rem;
                        }
                    }
                }
            }

            &:not(.swiper-slide-active) {
                .content {
                    // opacity: 0;
                }
            }

            &.swiper-slide-active {
                .content {
                    // opacity: 0;
                    // animation: 1s 1s animateslide;
                    // animation-fill-mode: forwards;

                    .title {
                        opacity: 0;
                        animation: 1s 1.5s animateslide;
                        animation-fill-mode: forwards;
                    }

                    .text {
                        opacity: 0;
                        animation: 1s 2s animateslide;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }

    }

    .visual-controls {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        z-index: 1;

        .visual-pagination {
            text-align: center;
            display: flex;
            justify-content: center;
            margin: 0;
            padding: .8125rem 1rem;

            .bullet {
                width: 4rem;
                height: .375rem;
                display: block;
                border-radius: 0;
                background: $white;
                opacity: 1;
                margin: 0 .5rem;
                cursor: pointer;

                &.active {
                    background: $color;
                }
            }
        }

    }

    @keyframes animateslide {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }


}