.page-header {
    padding: 3rem 0;
    // text-align: center;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }


    .title {
        margin: 0 0 1.5rem;

        @include media-breakpoint-down(md) {
            margin: 0 0 1rem;
        }

        @extend .typo-h1;
        margin-top: 0;
    }

    .subtitle {
        @extend .typo-label;
        color: $color;
    }

    .description {
        @extend .typo-rich;
    }

    .link {
        margin-top: 1.5rem;
    }
}