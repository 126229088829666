.events-list,
.single-event {
    padding: 3rem 0;
    position: relative;
    z-index: 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    --tec-color-link-accent: #FAA21B;
    --tec-color-link-accent-hover: #FAA21B;
    --tec-color-link-accent-hover: rgba(250, 162, 27, .8);
    --tec-color-icon-focus: #FAA21B;
    --tec-color-event-icon-hover: #FAA21B;
    --tec-color-accent-primary:#faa21b;
    --tec-color-accent-primary-background:rgba(51,74,255,.07);
    --tec-color-accent-primary-hover: rgba(250, 162, 27, .8);
    --tec-color-accent-primary-active: rgba(250, 162, 27, .9);
    --tec-color-button-primary:#faa21b;
    --tec-color-button-primary-hover:rgba(250, 162, 27, .8);
    --tec-color-button-primary-active:rgba(250, 162, 27, .9);
    --tec-color-background-primary-multiday: rgba(250, 162, 27, .24);
    --tec-color-background-primary-multiday-hover: rgba(250, 162, 27, .34);
    --tec-color-background-secondary-multiday: rgba(20, 24, 39, .24);
    --tec-color-background-secondary-multiday-hover: rgba(20, 24, 39, .34);
    --tec-color-accent-primary-week-event: rgba(250, 162, 27, .1);
    --tec-color-accent-primary-week-event-hover: rgba(250, 162, 27, .2);
    --tec-color-accent-primary-week-event-featured: rgba(250, 162, 27, .04);
    --tec-color-accent-primary-week-event-featured-hover: rgba(250, 162, 27, .14);


    // MONTH
    .tribe-common-l-container,
    .tribe-events-l-container {
        padding: 0 !important;
    }

    .tribe-events .datepicker table {
        width: 100%;
    }

    // SINGLE
    .event-image {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .tribe-events-meta-group-organizer {
        display: none;
    }

    .tribe-venue {
        text-decoration: underline;
    }

    .tribe-address {
        display: block;
    }
}