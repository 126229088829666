.block-gallery {

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
        margin-bottom: 2rem;
    }

    padding: 3rem 0;
    background: $light;
    margin-bottom: 3rem;
    max-width: 100vw;
    overflow-x: clip;

    .intro {
        text-align: center;
        margin-bottom: 2.5rem;

        .title {

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            margin: 0 0 1.5rem;

            h2 {
                @extend .typo-h2;
                margin: 0;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
            }
        }

        .subtitle {
            @extend .typo-label;
        }
    }

    .gallery-slider {
        overflow: hidden;
        position: relative;

        .slide {

            .image {
                background: $light;

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                    box-shadow: 0;
                    // transition: $trans-start;

                    // &:hover {
                    //     transform: $trans-end;
                    //     filter: $filter;

                    // }
                }
            }

            // &:not(.swiper-slide-active) {
            //     .image {
            //         filter: brightness(.6);
            //     }
            // }

        }

        .gallery-nav {
            position: absolute;
            top: 50%;
            background: $white;
            z-index: 2;
            margin-top: -1rem;
            font-size: 1.25rem;
            cursor: pointer;
            transition: .3s;
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .75;

            &:hover {
                opacity: 1;
            }

            &.prev {
                left: .5rem;
            }

            &.next {
                right: .5rem;
            }
        }
    }

    &:not(.white-space)+.white-space,
    &:not(.white-space)+.with-bg {

        @include media-breakpoint-down(lg) {
            margin-top: -2rem;
        }
        margin-top: -3rem;
    }
}

// .block-two-columns.with-media + .block-gallery {
//     margin-top: -5rem;

//     @include media-breakpoint-down(lg) {
//         margin-top: -3rem;
//     }
// }