.news {
    margin-bottom: 2rem;

    .wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 1rem;
        box-shadow: 0 0 0.5rem rgba($dark, 0.15);
        border-radius: 1rem;
        background: $white;

        &:hover {
            box-shadow: 0 0 0.5rem rgba($dark, 0.25);
        }

        .image-box {
            position: relative;

            .image {
                display: block;
                background: $light;
                border-radius: 1rem 1rem 0 0;

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                    border-radius: 1rem 1rem 0 0;
                }
            }

            .category {
                display: inline-block;
                position: absolute;
                bottom: -0.625rem;
                background: $color;
                color: $white;
                padding: 0.25rem 0.5rem;
                min-width: 50%;
                text-transform: uppercase;
                text-decoration: none;
                margin-top: auto;
                font-size: 0.75rem;

                // @include media-breakpoint-down(md) {
                //     position: relative;
                //     bottom: auto;
                // }

                &:hover {
                    background: $dark;
                    color: $white;
                }
            }
        }

        .content {
            padding: 1.625rem 0 0;

            @include media-breakpoint-down(md) {
                padding-top: 1.25rem;
            }

            .title {
                display: block;
                text-decoration: none;
                margin-bottom: 1rem;
                // padding-bottom: 1rem;
                // border-bottom: 1px solid $color;

                h4 {
                    font-size: 1rem;
                    line-height: 1.25;
                    text-decoration: none;
                    transition: 0.15s ease-in-out;
                    font-weight: bold;
                    // color: $color;
                    // text-transform: uppercase;
                    margin: 0;
                }

                &:hover {
                    // text-decoration: underline;
                }
            }

            // .text {
            //     font-size: .875rem;
            // }
        }

        .date {
            padding: 0;
            font-size: 0.75rem;
            color: $black;
            text-transform: uppercase;
            margin-top: auto;
        }
    }
}

.blog-grid {
    padding: 5rem 0;

    @include media-breakpoint-down(md) {
        padding: 3rem 0;
    }
}

.post-share {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
        justify-content: flex-start;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        width: 2rem;
        height: 2rem;
        // border: 1px solid $black;
        text-decoration: none;
        font-size: 0.875rem;
        cursor: pointer;
        border-radius: 50%;
        background: $light;

        &:hover {
            background: $color;
            color: $white;
        }
    }
}

.blog-newsletter {
    .wrapper {
        display: flex;
        align-items: start;
        gap: 2rem;
        padding: 3rem 0;
        border-top: 1px solid $color;
        border-bottom: 1px solid $color;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .title {
            font-family: $font;
            font-size: 1.5rem;
            line-height: 1.25;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            color: $color;
        }

        .text {
            margin: 0;
            font-size: 1rem;
            line-height: 1.5;
        }

        .btn {
            flex-shrink: 0;
        }
    }
}

.blog-categories {
    gap: 1rem;

    .category {
        display: flex;
        background: $color;
        color: $white;
        flex-direction: column;
        height: 100%;
        padding: 1rem;
        border-radius: 1rem;
        text-decoration: none;

        .title {
            display: block;
            font-size: 1.25rem;
            line-height: 1.25;
            margin-bottom: 0.5rem;
            font-weight: bold;
        }

        .text {
            margin-bottom: 1rem;
        }

        .btn {
            margin-top: auto;
            margin-left: auto;

            &:hover {
                background: $white;
                color: $dark;
            }
        }

        &:hover {
            background: $dark;

            .btn {
                background: $color;
                color: $white;
            }
        }
    }
}

.related-posts {
    background: $light;
    padding: 3rem 0;
    margin-bottom: 3rem;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
        margin-bottom: 2rem;
    }

    .intro {
        margin: 0 0 3rem;
        text-transform: uppercase;
        color: $color;
        text-align: center;
        @extend .typo-h3;

        @include media-breakpoint-down(md) {
            margin: 0 0 2rem;
        }
    }

    .news-grid {
        .news {
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-down(lg) {
                &:nth-child(3) {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}

.blog-header {
    background: rgba($dark, 0.075);
    margin-bottom: 3rem;

    @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
    }
}

.single-news .page-content h2,
.single-news .page-content h3,
.single-post .page-content h2,
.single-post .page-content h3 {
    color: var(--color) !important;
}

.post-content-blog {
    .container {
        padding: 0;
    }
}

.blog-cta {
    background: $color;
    padding: 1.5rem;
    box-shadow: 0 0 0.5rem rgba($dark, 0.15);
    border-radius: 1rem;
    position: sticky;
    top: 6.5rem;
    margin-bottom: 3rem;

    @include media-breakpoint-down(md) {
        position: relative;
        top: auto;
        margin-bottom: 2rem;
    }

    .title {
        display: block;
        color: $white;
        font-size: 1.25rem;
        line-height: 1.25;
        margin-bottom: 1rem;
        font-weight: bold;
    }

    .link {
        display: flex;
        justify-content: end;
    }
}

.blog-widgets {
    background: $light;
    padding: 1.5rem;
    box-shadow: 0 0 0.5rem rgba($dark, 0.15);
    border-radius: 1rem;
    margin-bottom: 1.5rem;

    .widget {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid $dark;

        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
        }

        .title {
            display: block;
            color: $dark;
            font-size: 1.25rem;
            line-height: 1.25;
            margin-bottom: 1rem;
            font-weight: bold;
        }

        .links {
            list-style-type: none;
            padding-left: 0;
            margin-left: 1.75rem;
            margin-bottom: 1rem;

            @include media-breakpoint-down(md) {
                // margin-left: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            li {
                position: relative;
                margin-bottom: .5rem;
                line-height: 1.4;
                font-size: .875rem;

                &:before {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: -1.75rem;
                    flex: 0 0 auto;
                    height: 1rem;
                    width: 1rem;
                    margin-top: .175rem;
                    margin-right: .5rem;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    font-size: .5rem;
                    color: $color;
                    content: "\f00c";
                    background: $white;
                    font-family: $font-icons;
                }

                a {
                    font-weight: bold;
                    text-decoration: none;

                    &:hover {
                        color: inherit;
                        text-decoration: underline;
                    }
                }

            }
        }

        &.latest {
            li {
                &:before {
                    content: "\f303";
                }
            }
        }

        &.categories {
            li {
                &:before {
                    content: "\f187";
                }
            }
        }
    }
}

.blog-btt {
    position: fixed;
    bottom: 0;
    right: 0;
    background: $color;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 1.5rem;
    color: $white;
	opacity: 0;

	&.show {
		opacity: 1;
	}

    &:hover {
        background: $dark;
    }
}