.page-content {
    // padding: 2rem 0 0;
}

.related-pages {
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .title {
        margin-bottom: 2.5rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 1.5rem;
        }

        h3 {
            @extend .typo-h2;
            margin: 0;
        }
    }

    .page-grid {
        display: grid;
        grid-template-columns: repeat(var(--cols), 1fr);
        gap: 1rem 2rem;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
        }

        .page-item {
            background: $color;
            display: grid;
            align-items: center;
            border-radius: .25rem;
            grid-template-columns: 5rem 1fr;
            text-decoration: none;

            .image {
                height: 100%;
                width: 100%;
                border-radius: .25rem 0 0 .25rem;
                overflow: hidden;
                background: $white;
                display: flex;
                align-items: center;

                img {
                    max-height: 5rem;
                    object-fit: contain;
                }
            }

            .label {
                @extend .typo-label;
                font-size: 1rem;
                margin: 0;
                text-transform: uppercase;
                padding: 1rem;
                color: $white;
            }

            &:hover {
                background: $dark;
            }
        }
    }
}