.case-grid {
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }
}

.case {
    margin-bottom: 2rem;

    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        box-shadow: 0 0 .5rem rgba($dark, .15);
        border-radius: 1rem;

        &:hover {
            box-shadow: 0 0 .5rem rgba($dark, .25);
        }

        .image-box {
            position: relative;

            .image {
                display: block;
                background: $light;
                border-radius: 1rem 1rem 0 0;

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                    border-radius: 1rem 1rem 0 0;
                }
            }

            .category {
                background: $color;
                color: $white;
                padding: .25rem .5rem;
                min-width: 50%;
                display: inline-block;
                text-transform: uppercase;
                text-decoration: none;
                margin-top: auto;
                font-size: .75rem;
                position: absolute;
                bottom: -.625rem;
                max-width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                @include media-breakpoint-down(md) {
                    position: relative;
                    bottom: auto;
                }

                &:hover {
                    background: $dark;
                    color: $white;
                }
            }

        }

        .content {
            padding: 1.625rem 0 0;

            @include media-breakpoint-down(md) {
                padding-top: 1rem;
            }

            .title {
                text-decoration: none;
                display: block;
                margin-bottom: 1rem;
                // padding-bottom: 1rem;
                // border-bottom: 1px solid $color;

                h4 {
                    font-size: 1rem;
                    line-height: 1.25;
                    text-decoration: none;
                    transition: .15s ease-in-out;
                    font-weight: bold;
                    // color: $color;
                    // text-transform: uppercase;
                    margin: 0;
                }

                &:hover {
                    // text-decoration: underline;
                }
            }

            // .text {
            //     font-size: .875rem;
            // }
        }
    }
}

.case-filters {
    padding: 0 0 3rem;

    @include media-breakpoint-down(md) {
        padding: 0 0 2rem 0;
    }

    h4 {
        @extend .typo-h5;
    }

    .filter-reset {
        display: block;
        padding: .375rem .75rem .375rem .75rem;
        font-size: 1rem;
        text-align: center;
        line-height: 1.5;
        color: $white;
        background-color: $color;
        border-radius: 0.25rem;
        border: 0;

        &:hover {
            background: $dark;
        }
    }
}