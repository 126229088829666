.block-reviews {
	padding: 3rem 0;

	@include media-breakpoint-down(lg) {
		padding: 2rem 0;
	}

	.intro {
		margin-bottom: 2.5rem;

		.title {
			margin: 0 0 1.5rem;

			@include media-breakpoint-down(md) {
				margin: 0 0 1rem;
			}

			h2 {
				margin: 0;
				@extend .typo-h2;
			}

			h3 {
				margin: 0;
				@extend .typo-h3;
			}
		}
	}

	.reviews {
		overflow: clip;
		background: #faa21b;
		border-radius: 2rem;

		.review-cover {
			border-radius: 2rem;
		}

		.reviews-slider {
			position: relative;

			.review-slide {
				display: flex;
				color: #fff;
				background: #faa21b;
				flex-direction: column;
				height: auto !important;
				padding: 3rem 4rem;
				text-decoration: none;
				transition: 0.15s ease-in-out;
				font-size: 1.25rem;
				line-height: 1.25;
				// margin-bottom: 0.5rem;
				font-weight: bold;
				text-align: center;
				align-items: center;
				justify-content: center;
				font-style: italic;
			}

			.review-nav {
				position: absolute;
				top: 50%;
				background: $white;
				z-index: 2;
				margin-top: -1rem;
				font-size: 1.25rem;
				cursor: pointer;
				transition: 0.3s;
				height: 2rem;
				width: 2rem;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: 0.75;

				&:hover {
					opacity: 1;
				}

				&.prev {
					left: 0.5rem;
				}

				&.next {
					right: 0.5rem;
				}
			}
		}

		&.with-cover {
			// display: grid;
			// grid-template-columns: auto 60%;
			// gap: 0 1.5rem;
			padding: 2rem;
			border-radius: 2rem;

			.review-cover {
				picture,
				img {
					border-radius: 2rem;
					overflow: clip;
				}
			}
		}
	}
}
