.category-header {
    background: $light;

    .header-wrapper {
        position: relative;

        .header-content {
            position: relative;
            z-index: 1;
            padding: 3rem 1rem;

            @include media-breakpoint-down(lg) {
                padding: 2rem 1rem;
            }

            .title {
                margin: 0 0 1.5rem;

                @include media-breakpoint-down(md) {
                    margin: 0 0 1rem;
                }

                @extend .typo-h1;
                margin-top: 0;
            }

            .subtitle {
                @extend .typo-label;
                color: $color;
            }

            .description {
                @extend .typo-rich;
            }

            .link {
                margin-top: 1rem;
            }
        }

        .header-image {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;

            @include media-breakpoint-down(lg) {
                position: relative;
            }

            picture,
            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
                position: relative;

                &:before {
                    display: block;
                    content: "";
                    height: 100%;
                    left: 0;
                    top: 0;
                    width: 25%;
                    background: linear-gradient(90deg, rgba($light, 1) 0%, rgba($light, 0) 100%);
                    position: absolute;
                    z-index: 1;
                }
            }
        }

    }
}