.category-grid {
    margin: 3rem 0;

    @include media-breakpoint-down(lg) {
        margin: 2rem 0;
    }

    .category-wrapper {
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
        }

        .category-item {
            text-decoration: none;
            position: relative;

            .image {
                // margin-bottom: 1rem;
                display: block;
                background: $light;
                position: relative;

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                    // transition: $trans-start;

                    &.alternative {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                    }
                }

                &:hover {

                    picture,
                    img {
                        // transform: $trans-end;
                        // filter: $filter;
                        opacity: 1;
                    }
                }
            }

            .title {
                position: absolute;
                bottom: 1.5rem;
                left: 0;
                display: flex;
                margin: 0;
                padding: 1rem;
                max-width: 80%;
                transition: .15s ease-in-out;
                font-size: 1rem;
                line-height: 1.25;
                text-decoration: none;
                color: $white;
                background: $color;
                font-family: $font;
                text-transform: uppercase;
                font-weight: bold;
                height: 4rem;
                align-items: center;
                width: 80%;

                &:hover {
                    background: $color;
                }
            }

            .icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 4rem;
                height: 4rem;
                display: flex;
                font-size: 2.5rem;
                align-items: center;
                justify-content: center;
                color: $white;
                background-color: $color;

                img {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

        }
    }
}