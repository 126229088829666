// @import url('https://fonts/modula.googleapis.com/css2?family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
    font-family: 'Modula Sans';
    src: url('../fonts/modula/Modula-Light.eot');
    src: url('../fonts/modula/Modula-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/modula/Modula-Light.woff2') format('woff2'),
        url('../fonts/modula/Modula-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Modula Sans';
    src: url('../fonts/modula/Modula-LightItalic.eot');
    src: url('../fonts/modula/Modula-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/modula/Modula-LightItalic.woff2') format('woff2'),
        url('../fonts/modula/Modula-LightItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Modula Sans';
    src: url('../fonts/modula/Modula-Bold.eot');
    src: url('../fonts/modula/Modula-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/modula/Modula-Bold.woff2') format('woff2'),
        url('../fonts/modula/Modula-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Modula Sans';
    src: url('../fonts/modula/Modula-BoldItalic.eot');
    src: url('../fonts/modula/Modula-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/modula/Modula-BoldItalic.woff2') format('woff2'),
        url('../fonts/modula/Modula-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

