/* SASS Variables */
$black: #000000;
$white: #FFFFFF;
$light: #ededed;
$dark: #3E3E3D;
$color: #FAA21B;
$gradient: radial-gradient(linear, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
$font: 'Modula Sans', sans-serif;
$font-icons: 'Font Awesome 6 Pro';
$font-brands: 'Font Awesome 6 Brands';

/* CSS Variables */
:root {
    --font: 'Modula Sans', sans-serif;
    --font-icons: 'Font Awesome 6 Pro';
    --font-brands: 'Font Awesome 6 Brands';
    --font-duotone: 'Font Awesome 6 Duotone';
    --color: #FAA21B;
    --plyr-color-main: #FAA21B;
    --fa-primary-color: #FAA21B;
    --fa-secondary-color: #000;
}

/* Font-size reference for rem unit */
html {
    font-size: 16px;
    overflow-x: hidden;
    // overflow-y: auto;
    scroll-behavior: smooth;

    @include media-breakpoint-down(md) {
        overflow-x: hidden;
    }
}

/* reset outlines on focus and fields */
*:focus,
input:required {
    outline: 0 !important;
    box-shadow: none !important;
}

/* Body base styles */
body {
    position: relative;
    color: $dark;
    font-family: $font;
    font-weight: 400;
    line-height: 1.4;
    vertical-align: baseline;
    letter-spacing: 0.01em;
}

/* Picture tag fix */
picture {
    display: block;
}

p {
    margin-bottom: .875rem;
}
/* reset last paragraph margin */
*>p:last-child {
    margin-bottom: 0;
}

/* General links */
a {
    color: inherit;
    transition: .15s;
    text-decoration-thickness: from-font !important;
    text-underline-offset: 1px;

    &:hover {
        color: $color;
    }
}

/* Image zoom effect */
.zoom-fx {
    display: block;
    width: 100%;
    overflow: hidden;

    picture, 
    img {
        position: relative;
        transition: transform .75s cubic-bezier(.45, 0, .6, 1);

        &:hover {
            transform: scale3d(1.125, 1.125, 1);
            transition: transform .75s cubic-bezier(.45, 0, .6, 1);
        }
    }
}

/* BGs */
.bg-color {
    background-color: $color !important;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none;
    animation: none;
  }
}