.breadcrumbs {
    padding: .75rem 0;
    background-color: $light;
    border-bottom: 1px solid rgba($dark, .15);

    .breadcrumb {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        font-size: .75rem;
        line-height: 1.25;
        list-style: none;
        z-index: 1;
        position: relative;
        color: $black;

        li {
            display: inline-block;

            &::before {
                font-family: $font-icons;
                content: "\f105";
                transform-origin: center;
                font-size: .75rem;
                padding: 0;
                margin-right: .375rem;
            }

            &:first-child {

                &::before {
                    display: none;
                }
            }

            a {
                transition: .15s ease-in-out;
                text-decoration: none;
                // font-weight: bold;

                &:hover {
                    color: $color;
                }
            }
        }
    }

    .single-post & {
        .breadcrumb {
            li {
                &:last-child {
                    // display: none;
                }
            }
        }
    }

}