.btn {
    font-size: 1rem;
    line-height: 1;
    display: inline-flex;
    width: auto;
    font-weight: bold;
    align-items: center;
    transition: .15s ease-in-out;
    font-family: $font;
    padding: .5rem .75rem;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: .25rem;
    background: $color;
    color: $white;
    text-align: center;

    @include media-breakpoint-down(md) {
        font-size: .875rem;
    }

    &:hover {
        background: $dark;
        color: $white;
    }

    &.inverted {
        background: $white;
        color: $dark;

        &:hover {
            background: $dark;
            color: $white;
        }
    }

    &.dark {
        background: $dark;
        color: $white;

        &:hover {
            background: $color;
            color: $white;
        }
    }
}

.btn-icon {
    display: inline-flex;
    // width: 100%;
    align-items: center;

    i {
        font-size: 1.25rem;
        margin-right: .75rem;
        --fa-primary-color: #FFF;
        --fa-secondary-color: #000;
    }

    &:hover {
        i {
            color: $white;
            --fa-secondary-color: #FAA21B;
        }
    }
}

.btn-text {
    font-size: 1.0625rem;
    // letter-spacing: 0.05em;
    font-weight: bold;
    line-height: 1;
    display: inline;
    align-items: center;
    transition: .15s ease-in-out;
    // padding: 0 0 .875rem;
    text-transform: uppercase;
    text-decoration: none;

    @include media-breakpoint-down(md) {
    font-size: 1rem;
    //     // padding: .875rem;
    }

    i {
        margin-left: .5rem;
        transition: .3s;
    }

    &:hover {

        i {
            margin-left: 1rem;

        }
    }
}