.block-downloads {
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }

    .intro {
        text-align: center;
        margin-bottom: 3rem;
    }

    .elements {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem 2rem;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;
        }

        .element-item {
            position: relative;
            // margin-bottom: 2.5rem;
            display: grid;
            grid-template-columns: 30% 1fr;
            gap: 1rem;

            @include media-breakpoint-down(md) {
                // grid-template-columns: 1fr;
            }

            label {
                display: inline-block;
                cursor: pointer;
                font-size: .875rem;
            }

            .element-trigger {
                display: none;

                &:checked~.image {

                    .check {
                        opacity: 1;
                    }
                }
            }

            .image {
                display: block;
                position: relative;
                border: 1px solid $dark;

                @include media-breakpoint-down(md) {
                    // display: none;
                }

                picture,
                img {
                    width: 100%;
                    object-fit: contain;
                }

                img {
                    // border: 1px solid $medium;
                }

                &:hover {

                    picture,
                    img {
                        opacity: 1;
                    }
                }
            }

            .text {

                .title,
                .subtitle {
                    color: $color;
                    font-size: 1rem;
                    line-height: 1;
                    margin-bottom: .75rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    display: block;
                }

                .check {
                    @extend .form-check-input;
                    margin-top: .1875rem;
                    margin-right: .25rem;
                    border-color: $dark;

                    &:focus {
                        border-color: $dark;
                    }

                    &:checked {
                        background-color: $color;
                        border-color: $color;
                    }
                }
            }

        }
    }

    .content {
        margin-bottom: 3rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }

        .title {

            h2 {
                @extend .typo-h2;
            }
        }

        .text {
            @extend .typo-rich;

        }
    }

    .form {
        padding: 3rem 0;

        @include media-breakpoint-down(md) {
            padding: 2rem 0;
        }

        form {

            .warning {
                font-size: .75rem;
                margin-top: .25rem;
                color: red;
            }

            .form-group {

                p {
                    font-size: .875rem;
                }

                label {
                    margin-bottom: .25rem;
                }

                .form-control {
                    // background: transparent;
                    border-color: rgba($dark, .25);
                    border-radius: .25rem;
                }

                textarea.form-control {
                    height: auto;
                }

                .form-select {
                    // background: transparent;
                    border-color: rgba($dark, .25);
                    border-radius: .25rem;
                }

                .form-check {

                    .form-check-input {
                        margin-top: .125rem;
                    }
                }

                a {
                    color: $color;
                    font-weight: bold;
                }
            }

            .send-group {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .button-wrapper {
                    position: relative;
                }

                span {
                    order: 1;
                }

                .btn {
                    order: 2;
                    border: 0;

                    &:disabled {
                        background: rgba($dark, .5);
                        pointer-events: none;
                    }
                }
            }

            .extra {
                margin-bottom: 2rem;
                border: 1px solid $color;
                padding: 1rem;

                span {
                    font-size: 1rem;
                    line-height: 1.25;
                    display: block;
                }

                a {
                    margin-top: 1rem;
                    font-weight: bold;
                }
            }

            // CF7 overrides
            br {
                display: none !important;
            }

            .wpcf7-not-valid-tip {
                font-size: .75rem;
                margin-top: .25rem;
                color: red;
            }

            .wpcf7-acceptance+.wpcf7-not-valid-tip {
                margin-top: 0;
                margin-bottom: 1rem;
            }

            .wpcf7-form-control-wrap {
                display: block;
            }

            .wpcf7-list-item {
                margin-left: 1rem;

                &:first-child {
                    margin-left: 0;
                }
            }

            .wpcf7-response-output {
                border-color: red !important;
                margin: 0 !important;
                padding: .25rem .5rem !important;
            }

            // [data-class="wpcf7cf_group"] {
            //     width: 100%;

            //     +.row {
            //         margin-top: 1rem;
            //     }
            // }

            .wpcf7-spinner {
                position: absolute;
                width: 100%;
                height: 100%;
                border: none;
                border-radius: .25rem;
                margin: 0;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $dark;

                &:before {
                    display: none;
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background-color: $color;
                    opacity: 0.75;
                    width: 24px;
                    height: 24px;
                    border: none;
                    border-radius: 100%;
                    padding: 0;
                    margin: 0 24px;
                    transform: scaleX(1);
                    animation: pulse 3s infinite;
                }


                @keyframes pulse {
                    0% {
                        transform: scale(0.75);
                    }

                    50% {
                        transform: scale(1);
                    }

                    100% {
                        transform: scale(0.75);
                    }
                }

            }

        }
    }

}