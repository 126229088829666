.jobs-list {
    padding: 3rem 0;

    @include media-breakpoint-down(lg) {
        padding: 2rem 0;
    }


    .intro {
        // text-align: center;
        margin-bottom: 2.5rem;

        .title {
            margin: 0 0 1.5rem;

            @include media-breakpoint-down(md) {
                margin: 0 0 1rem;
            }

            h2 {
                @extend .typo-h2;
                margin: 0;
            }

            h3 {
                @extend .typo-h3;
                margin: 0;
            }
        }
    }

    .job-item {
        display: grid;
        grid-template-columns: 1fr 3rem;
        gap: 1rem;
        padding: 1rem;
        box-shadow: 0 0 .5rem rgba(62, 62, 61, .15) !important;
        border-radius: .75rem;
        margin-bottom: 1rem;
        align-items: center;
        text-decoration: none;

        &.with-image {
            grid-template-columns: 4rem 1fr 3rem;
        }

        @include media-breakpoint-down(md) {

            grid-template-columns: 1fr 3rem;

            .image {

                display: none;
            }
        }

        .content {
            .title {
                @extend .typo-h3;
                margin: 0 0 .5rem;
                display: block;
                // color: $color;
            }

            .location {
                display: block;
            }
        }

        .link {
            .bullet {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                background: $color;
                height: 3rem;
                width: 3rem;
                border-radius: 50%;
                text-decoration: none;
            }
        }

        &:hover {
            box-shadow: 0 0 .5rem rgba(62, 62, 61, .25) !important;

            .content {
                .title {
                    // color: $dark;
                }
            }

            .link {
                .bullet {
                    background: $dark;
                }
            }
        }
    }
}