.top-bar {
    background: $dark;
    padding: .5rem 0;

    .top-wrapper {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .top-menu {
        // margin-left: 2rem;

        a {
            color: $white;
            text-decoration: none;
            font-size: 1rem;
            margin-left: 1rem;
            line-height: 1;
            display: inline-block;
            font-weight: bold;

            @include media-breakpoint-down(xxl) {
                font-size: .875rem;
            }

            @include media-breakpoint-down(md) {
                font-size: .75rem;
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                color: $color;
            }
        }

            @include media-breakpoint-down(md) {
                display: flex;
                // margin-left: 0;
                justify-content: space-between;
                font-size: .75rem;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
    }

    .social-links {
        margin-left: 2rem;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            display: none;
        }

        a {
            color: $white;
            text-decoration: none;
            font-size: .875rem;
            line-height: 1;
            margin-left: .75rem;
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: $color;
            }
        }
    }
}

header.header {
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 0;
    transition: .15s ease-in-out;
    // box-shadow: 0 .125rem .25rem rgba($black, .2);
    border-bottom: 1px solid rgba($dark, .25);

    @include media-breakpoint-down(lg) {
        position: relative;
    }

    .navbar {
        padding: 0;

        // LOGO
        .navbar-brand {
            padding: .25rem 0;
            margin: 0 2rem 0 0;
            display: flex;
            align-items: center;

            img {
                height: 4.5rem;
                max-width: 9.25rem;

                @include media-breakpoint-down(xl) {
                    height: 4rem;
                }

                @include media-breakpoint-down(md) {
                    height: 3rem;
                }
            }
        }

        // MENU

        // TOGGLERS
        .header-toggler {
            border: 0;
            color: $black;
            background: $light;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            transition: .15s;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 50%;
            flex-shrink: 0;
            margin: 1.5rem 0 1.5rem 1rem;

            i {
                font-size: 1rem;
                transform: scale(1.25);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .15s;

                &:hover {
                    color: inherit;
                }
            }
        }

        // contact TOGGLER
        .contact-toggler {
            color: $white;
            background: $color;
            text-decoration: none;

            &:hover {
                color: $white;
                background: rgba($color, .75);
            }
        }

        // SEARCH TOGGLER
        .search-toggler {
            color: $white;
            background: $color;

            &[aria-expanded="true"] {
                color: $white;
                background: rgba($color, .75);

                i:before {
                    content: "\e59b";
                }
            }
        }

        // LANGUAGE
        .language-toggler {
            color: $white;
            background: $color;

            &[aria-expanded="true"] {
                color: $white;
                background: rgba($color, .75);
            }
        }

        .language-selector {

            .dropdown-menu {
                margin: 0;
                padding: 0;
                border: 1px solid rgba($dark, .25);
                border-top: 0;

                .dropdown-item {
                    font-size: .875rem;
                    height: 2rem;
                    padding: .5rem 1rem;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    text-transform: uppercase;
                    font-family: $font;

                    &:hover,
                    &:focus {
                        background-color: $color;
                        color: $white;
                    }

                    &.active {
                        background-color: $color;
                        text-decoration: none;
                        color: $white;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    // SEARCH FORM
    .search {
        // padding: 1rem;
        background: $color;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        margin: 0;
        top: 5.5rem;
        border-top: 1px solid $light;
        box-shadow: 0 .125rem .25rem rgba($black, .2);

        // &:after {
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     background-color: rgba($black, .2);
        //     left: 0;
        //     top: 100%;
        //     height: 100vh;
        //     display: block;
        // }

        // &.collapsing {
        //     -webkit-transition: none;
        //     transition: none;
        // }

        @include media-breakpoint-down(md) {
            // position: relative;
            margin: 0;
        }

        .search-form {
            display: flex;
            margin: 0;
            align-items: center;
            padding: .5rem 0;

            .form-control {
                border: 0;
                height: 3rem;
                font-size: 1.25rem;
                padding: 0;
                background: $color;
                color: $white;

                &::placeholder {
                    color: $light;
                }
            }

            .btn {
                border: 0;
                border: 0;
                border-left: 0;
                color: $color;
                background: $light;
                width: 2.5rem;
                height: 2.5rem;
                margin-left: 1rem;
                transition: .15s;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 50%;

                i {
                    font-size: 1rem;
                    transform: scale(1.25);
                    margin: 0;
                }

                &:hover {
                    color: $green;
                    background: $light;
                }

                &.close {
                    background: $light;
                    display: none;
                    color: $black;

                    &:hover {
                        // color: $black;
                        // background: $light;
                    }
                }
            }
        }
    }

}