.typo-h1 {
	font-size: 2.25rem;
	font-family: $font;
	line-height: 1;
	font-weight: bold;
	margin: 1rem 0;

	@include media-breakpoint-down(lg) {
		font-size: 2rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.75rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.625rem;
	}
}

.typo-h2 {
	font-size: 2rem;
	font-family: $font;
	line-height: 1;
	font-weight: bold;
	margin: 1rem 0;

	@include media-breakpoint-down(lg) {
		font-size: 1.75rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.5rem;
	}
}

.typo-h3 {
	font-size: 1.75rem;
	font-family: $font;
	line-height: 1;
	font-weight: bold;
	margin: 1rem 0;

	@include media-breakpoint-down(lg) {
		font-size: 1.5rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.25rem;
	}
}

.typo-h4 {
	font-size: 1.5rem;
	font-family: $font;
	line-height: 1;
	font-weight: bold;
	text-transform: uppercase;
	margin: 1rem 0;

	@include media-breakpoint-down(md) {
		font-size: 1.125rem;
	}
}

.typo-h5 {
	font-size: 1.25rem;
	font-family: $font;
	line-height: 1;
	font-weight: bold;
	text-transform: uppercase;
	margin: 1rem 0;

	@include media-breakpoint-down(md) {
		font-size: 1rem;
	}
}

.typo-h6 {
}

.typo-label {
	font-size: 1.25rem;
	color: #faa21b;
	font-weight: bold;
	line-height: 1;
	margin: 1rem 0;

	@include media-breakpoint-down(md) {
		font-size: 1.125rem;
	}
}

.typo-rich {
	font-size: 1.0625rem;
	line-height: 1.4;

	@include media-breakpoint-down(md) {
		font-size: 1rem;
	}

	p {
	}

	a {
		text-decoration: underline;
		font-weight: bold;

		&:hover {
			color: $color;
		}
	}

	h2 {
		@extend .typo-h2;
	}

	h3 {
		@extend .typo-h3;
	}

	h4 {
		@extend .typo-h4;
	}

	ul {
		list-style-type: none;
		padding-left: 0;
		margin-left: 2rem;
		margin-bottom: 1rem;

		@include media-breakpoint-down(md) {
			// margin-left: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		li {
			margin-bottom: 0.5rem;
			line-height: 1.4;
			position: relative;
			// display: flex;
			break-inside: avoid;

			&:before {
				position: absolute;
				flex: 0 0 auto;
				left: -2rem;
				top: 0;
				height: 1.25rem;
				width: 1.25rem;
				margin-top: 0.125rem;
				margin-right: 0.5rem;
				display: flex;
				border-radius: 50%;
				align-items: center;
				justify-content: center;
				font-size: 0.625rem;
				color: $white;
				content: "\f00c";
				// content: "";
				background: $color;
				font-family: $font-icons;

				@include media-breakpoint-down(md) {
					// position: absolute;
					// left: -1.5rem;
					// top: .1285rem;
					margin-top: 0.175rem;
					height: 1rem;
					width: 1rem;
					font-size: 0.5rem;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ol {
		counter-reset: item;
		list-style-type: none;
		padding-left: 0;
		margin-left: 2rem;
		margin-bottom: 1rem;

		li {
			counter-increment: item;
			margin-bottom: 0.5rem;
			position: relative;
			break-inside: avoid;

			&::before {
				content: counter(item);
				background: $color;
				color: #fff;
				border-radius: 2px;
				height: 1.25rem;
				min-width: 1.25rem;
				line-height: 1;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				left: -2rem;
				position: absolute;
				font-size: 0.75rem;
				top: 0.125rem;
			}

			&::after {
				content: "";
				position: absolute;
				display: inline-block;
				width: 0;
				height: 0;
				border-bottom: solid 4px transparent;
				border-left: solid 6px $color;
				border-top: solid 4px transparent;
				left: -0.75rem;
				top: 0.5rem;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
