.block-cta {
	padding: 3rem 0;

	@include media-breakpoint-down(lg) {
		padding: 2rem 0;
	}

	.wrapper {
		background-color: $color;
		position: relative;
		padding: 1rem;
		border-radius: 1rem;
		color: $white;
		// box-shadow: 0px .125rem .75rem rgba($black, .2);

		.content {
			display: flex;
			flex-direction: column;

			@include media-breakpoint-down(md) {
				margin-bottom: 1rem;
			}

			.title {
				margin: 0 0 0.5rem;

				h2 {
					@extend .typo-h2;
					margin: 0;
					color: $white !important;
				}
			}

			.text {
				@extend .typo-rich;
				margin-bottom: 1rem;
			}

			.btn {
				margin-top: auto;
				margin-left: auto;
			}
		}

		&:hover {
			background: $dark;

			.btn {
				background: $color;
				color: $white;
			}
		}
	}
}

.block-cta-blog {
	padding: 3rem 0;

	@include media-breakpoint-down(lg) {
		padding: 2rem 0;
	}

	.wrapper {
		background-color: $light;
		position: relative;
		padding: 0 2rem;
		border-radius: 1rem;
		display: grid;
		min-height: 20rem;
		gap: 2rem;
		grid-template-columns: 42% 1fr;

		@include media-breakpoint-down(md) {
			grid-template-columns: 1fr;
		}

		.media {
			position: relative;

			@include media-breakpoint-down(md) {
				width: 75%;
				margin: 0 auto;
			}

			picture {
				position: absolute;
				z-index: 3;
				bottom: 0;
				left: 0;
				right: 0;
				display: inline-block;

				@include media-breakpoint-down(md) {
					position: relative;
				}

				img {
					max-width: 330px;
					margin: auto;
					display: block;
					object-fit: contain;
					width: 100%;
					height: 100%;
					max-height: 100%;
				}
			}

			&::after {
				content: "";
				display: block;
				background: $color;
				border-radius: 10px;
				position: absolute;
				left: 0;
				top: 4em;
				bottom: 4em;
				right: 6%;
			}

			@include media-breakpoint-down(md) {
				order: 2;
			}
		}

		.content {
			padding: 3rem 0;
			align-self: center;

			@include media-breakpoint-down(md) {
				margin-bottom: 1rem;
				order: 1;
				padding-bottom: 0;
				text-align: center;
			}

			.title {
				margin: 0 0 0.5rem;

				h2 {
					@extend .typo-h2;
					margin: 0;
					color: $color !important;
				}
			}

			.text {
				@extend .typo-rich;
				margin-bottom: 1rem;
			}

			.btn {
				display: inline-block;
				position: relative;
				padding: 0.75rem 2rem 0.75rem 5rem;
				margin-top: 2rem;

				i {
					border-radius: 0.25rem;
					font-size: 1.5rem;
					height: 3rem;
					width: 3rem;
					left: 1rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background: $color;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);  transition: 0.15s ease-in-out;
				}

				&:hover {
					i {
						background: $dark;
                        transform: translateY(-50%) scale(1.25);
					}
				}
			}
		}
	}
}
