.system-pagination {
    padding: 4rem 0;

    @include media-breakpoint-down(lg) {
        padding: 3rem 0;
    }

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .pagination {
        display: flex;
        @include list-unstyled();
        @include border-radius();
        align-items: center;
    }

    .page-link {
        position: relative;
        font-size: 1rem;
        display: block;
        // padding: .25rem .5rem;
        // min-width: 2rem;
        margin: 0 .25rem;
        text-align: center;
        line-height: 1rem;
        color: $dark;
        border: 0;
        background: none;

        &:hover {
            z-index: 2;
            color: $color;
            text-decoration: none;
        }

        &[rel] {
            color: $black;

            &:hover {
                z-index: 2;
                color: $color;
                text-decoration: none;
                // background-color: $light;
                // border-color: $black;
            }
        }
    }

    .page-item {

        &:first-child {
            .page-link {
                margin-left: 0;
            }
        }

        &.active .page-link {
            z-index: 3;
            color: $color;
            background: none;

            &.dots {
                color: $dark;
            }
        }

        &.disabled .page-link {
            opacity: .25;
            pointer-events: none;
        }
    }
}