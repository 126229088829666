.case-header {
    padding: 3rem 0;
    background: $white;

    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

    .content {
        text-align: left;
        z-index: 1;

        .title {
            @extend .typo-h1;
            font-size: 3rem;
            margin-top: 0;
            margin-bottom: 1rem;
            color: $color;

            @include media-breakpoint-down(lg) {
                font-size: 2.5rem;
            }
        }

        .subtitle {
            @extend .typo-h4;
            text-transform: none;
            color: $color;
        }

        .meta {
            margin-top: 2rem;
            margin-bottom: 2rem;

            h4 {
                font-size: 1.125rem;
                color: $color;
                margin: 0 0 .25rem;
                font-weight: bold;
            }

            a {
                text-decoration: none;
                font-weight: bold;
            }

            span {
                font-size: 1rem;
            }

            ul {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 1rem;
                margin-left: 1.5rem;

                &:last-child {
                    margin-bottom: 0;
                }

                li {
                    margin-bottom: .25rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    position: relative;

                    &:before {
                        position: absolute;
                        left: -1.5rem;
                        top: .1875rem;
                        height: 1rem;
                        width: 1rem;
                        font-size: .5rem;
                        display: flex;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        color: $white;
                        content: "\f00c";
                        // content: "";
                        background: $color;
                        font-family: $font-icons;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                }
            }

            .location {
                margin-bottom: .875rem;
            }

            .sector {
                margin-bottom: .875rem;
                padding: 1rem;
                background: $color;
                color: $white;
                border-radius: 1rem;

                h4 {
                    color: $white;
                }
            }

            .products {
                .list {
                    columns: 3;
                    column-gap: 2rem;

                    @include media-breakpoint-down(xl) {
                        columns: 2;
                    }

                    @include media-breakpoint-down(sm) {
                        columns: 1;
                    }
                }
            }
        }
    }

    .image {
        position: relative;

        @include media-breakpoint-down(md) {
            align-self: flex-end;
        }

        picture,
        img {
            width: 100%;
            object-fit: contain;
            object-position: top;
            border-radius: 1rem;
        }
    }

    .link {
        margin-top: 1rem;
        display: flex;
        justify-content: end;

        .btn-icon {
            width: auto;
        }
    }
}