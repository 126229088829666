.homepage-news {
    margin: 3rem 0;

    @include media-breakpoint-down(md) {
        font-size: 2rem;
    }

    .news-title {
        margin-bottom: 3rem;

        h3 {
            @extend .typo-h3;
            color: $color;
            text-transform: uppercase;
            text-align: center;
        }
    }

    .news-grid {

        .news {
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-down(lg) {
                &:nth-child(3) {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                &:nth-child(2) {
                    display: none;
                }
            }

        }
    }
}
